import React from "react";

import tunde from "../../../assets/images/tunde.jpg";
import tim from "../../../assets/images/tim.jpg";

import TundeEducation from "../../../assets/images/tunde-education.png";
import TundeExperience from "../../../assets/images/tunde-experience.png";
import TimEducation from "../../../assets/images/tim-education.png";
import TimExperience from "../../../assets/images/tim-experience.png";
import qr1 from "../../../assets/images/qr-code (1).png";
import qr2 from "../../../assets/images/qr-code (2).png";

import { motion } from "framer-motion";
import {
  Container,
  InfoContainer,
  Heading,
  EducationContainer,
  EducationSubContainer,
  ExperienceContainer,
  ExperienceSubContainer,
  QR,
} from "./styles";

export default function SectionFive({ padding, margintop, mobilemargintop }) {
  const foundersInfo = [
    {
      name: "Tunde Animashaun",
      image: tunde,
      about:
        "Tunde co-founded Metaphor Laboratory Partners and leads the team  as the Managing Partner/Chief Executive Officer.   He has held progressive leadership roles such as Manager of Laboratory Operations at SNBL-CPC(Acquired by Pharmaron) and Director of Lab Services at Aeon Technologies which he helped grow revenue to the multi-millions from scratch.   Prior to that, he worked in Service Organization of Becton Dickinson were he served as the customer liaison for thousands of legacy series microbiology and blood culture platforms .  He began his career in the microbiology departments of  Johns Hopkins Medicine over a 10 year span.  He holds a Bachelor of Science in Medical Technology from Morgan State University and MPH from Johns Hopkins University.",
      education: [
        {
          img: TundeEducation,
        },
      ],
      experience: [
        {
          img: TundeExperience,
        },
      ],
      qr: qr1,
    },
    {
      name: "Tim Amukele",
      image: tim,
      about:
        "Tim co-founded Metaphor Laboratory Partners and serves as a Senior Partner. Tim directs clinical labs in sSA and sits on the board of Pathologist Overseas Inc, where also serves as President. Prior, Tim worked as the Chief of Pathology and Director of Clinical Laboratories at Johns Hopkins Bayview Medical Center. While at Hopkins, Tim was the Principal Investigator of numerous clinical projects from lab quality assessments to new instrument validations to using drones to transport biological samples. Tim holds a MD/PhD from Albert Einstein Medical College of Yeshiva University. He completed his residency from the prestigious laboratory residency program of the University of Washington in Seattle",
      education: [
        {
          img: TimEducation,
        },
      ],
      experience: [
        {
          img: TimExperience,
        },
      ],
      qr: qr2,
    },
  ];
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        translateY: 300,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        translateY: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
      id="team"
    >
      <Container
        padding={padding}
        margintop={margintop}
        mobilemargintop={mobilemargintop}
      >
        {foundersInfo.map((founder) => (
          <InfoContainer>
            <img src={founder.image} alt="" />
            <Heading>{founder.name}</Heading>
            <p>{founder.about}</p>
            <EducationContainer>
              {/* <p>Education</p> */}
              <EducationSubContainer>
                {founder.education.map((education) => (
                  <div>
                    <img src={education.img} alt="" />
                  </div>
                ))}
              </EducationSubContainer>
            </EducationContainer>
            <ExperienceContainer>
              {/* <p>Experience</p> */}
              <ExperienceSubContainer>
                {founder.experience.map((experience) => (
                  <div>
                    <img src={experience.img} alt="" />
                  </div>
                ))}
              </ExperienceSubContainer>
            </ExperienceContainer>
            <QR>
              <img src={founder.qr} alt="" />
            </QR>
          </InfoContainer>
        ))}
      </Container>
    </motion.div>
  );
}
