/** @format */

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

      *{
            box-sizing: border-box;

      }
      html{
            scrollbar-width: normal;
      }
      html::-webkit-scrollbar{
            width:.7em;
      }
      html::-webkit-scrollbar-thumb{
      background: #cecece;
      border-radius:1em;
}
   html::-webkit-scrollbar-thumb:hover{
      background: #111827;
}
      html::-webkit-scrollbar-track{
      background: #ffffff32;
}
a {
  text-decoration: none;
}


      body{
      background: ${({ theme }) => theme.colors.bg};
      font-family: 'inter';
      margin: 0;
      color:${({ theme }) => theme.colors.text};
      font-weight: 500;
      width: 100%;

}
      img{
      max-width: 100%;
}

.wordpress-post-container {
      max-width: 800px;
      margin:0 auto;
  padding: 20px;
  img {
      max-width: 100%;
      height: auto;
    }
}
    
    .wordpress-post-content {
      margin-top: 20px;
      line-height: 1.6;
      width: 100%;
      overflow:scroll;
    }
    
    /* Add more styles as needed */
    .wordpress-post-content p {
      color:black !important;
    }


`;
