import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import distribute from "../../assets/images/fluent-mdl2_distribute-down.svg";
import panel from "../../assets/images/material-symbols_lab-panel-outline.svg";
import carbon_plan from "../../assets/images/carbon_plan.svg";
import research from "../../assets/images/material-symbols_lab-research-outline.svg";
import tabler_test from "../../assets/images/tabler_test-pipe.svg";
import table from "../../assets/images/data+1 1.png";
import recruitment from "../../assets/images/fluent-mdl2_recruitment-management.svg";
import { BlueBoard } from "../../components/blueboard/BlueBoard";
import { motion } from "framer-motion";
import {
  Container,
  TopContainer,
  Problem,
  Data,
  Table,
  References,
  EndSection,
} from "./styles";

export default function Value() {
  const data1 = [
    {
      image: carbon_plan,
      heading: "Map + Plan",
      text: "We conduct assessment and mapping of laboratory infrastructure relative to population in low-resource regions to determine optimal diagnostics volume re-distribution.",
    },
    {
      image: panel,
      heading: "Build/Acquire + Certify",
      text: "We acquire and re-design or build low-footprint modular laboratories, forward stocking locations, and cold-chain facilities for remote and low volume clinical sites. We subsequently enroll in CAP proficiency program towards accreditation.",
    },
    {
      image: distribute,
      heading: "Hub/Bank + Distribute",
      text: "We hub specialized testing in reference labs.  Our labs have lower cost benefits due our Group/bulk purchasing power.   We also provide Biospecimen procurement services for consented and/or IRB/ethical committee approved Biospecimen banking protocol",
    },
  ];
  const data2 = [
    {
      image: recruitment,
      heading: "Recruit + Train",
      text: "We recruit and train our associates to international standards.  We sponsor associates to attain local and international laboratory and project management professional certifications",
    },
    {
      image: tabler_test,
      heading: "Test + Data mine",
      text: "We use best-in-class laboratory technology and assays to deliver quality and reliable results to our affiliate clinical sites. We provide data and analytics report to affiliates to enable public health surveillance and support precision medicine.",
    },
    {
      image: research,
      heading: "Research + Develop",
      text: "We conduct clinical programs and contract research for global biopharma clients who are looking to develop inclusive precision medicine or CDx.  We perform cost-effective validation studies for clients seeking FDA 510(k) clearance.",
    },
  ];

  return (
    <>
      <Header />

      <motion.div
        initial={{
          opacity: 0,
          // scale: 0.8,
          translateY: 300,
        }}
        whileInView={{
          opacity: 1,
          // scale: 1,
          translateY: 0,
        }}
        transition={{
          duration: 0.5,
          delay: 0,
        }}
      >
        <Container>
          <TopContainer>
            <Problem>
              <h1>The Problem</h1>
              <p>
                Many times do we hear of the sad story of loss due to delayed or
                mis-diagnosis. Mis-diagnosis is one of leading causes of
                preventable deaths in sub-Saharan Africa. A big contributing
                factor to this is the lack of quality diagnostics infrastructure
                and human capital.
              </p>
            </Problem>
            <Data>
              <h1>Some Data</h1>
              <p>
                A survey of laboratories in Africa performed in 2013 by Drs Tim
                Amukele and Lee Shroeder revealed:
                <br />
                &bull; &lt; 10% of sSA labs outside South Africa are accredited
                to internationally recognized quality standards
                <br />
                &bull; Average of 1.7 quality accredited labs per million people
                in sSA
              </p>
            </Data>
          </TopContainer>
          <Table>
            <img src={table} alt="" />
          </Table>
          <References>
            <p>
              <b>Reference:</b>
              <br />
              Lee F Schroeder MD/PhD, Amukele Timothy MD/PhD. Medical
              Laboratories in sub-Saharan African that meet international
              quality standards. American Journal of Clinical Pathology, Volume
              141, Issue 6, June 2014, Pages 791–795
            </p>
          </References>
          <EndSection>
            <BlueBoard
              data1={data1}
              data2={data2}
              margin={"0%"}
              heading={
                "Our Approach - Creating an effective high quality lab ecosystem"
              }
              subheading1={""}
              subheading2={""}
              align={"left"}
            />
          </EndSection>
        </Container>{" "}
      </motion.div>

      <Footer />
    </>
  );
}
