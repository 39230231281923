import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  MainHeading,
  CardContainer,
  Top,
  Heading,
  Div,
  Card,
  ImageContainer,
  CardHeading,
  Bottom,
} from "./styles";

export function BlueBoard({
  data1,
  data2,
  margin,
  heading,
  subheading1,
  subheading2,
  align,
}) {
  let navigate = useNavigate();
  return (
    <Container margin={margin}>
      <MainHeading>{heading}</MainHeading>
      <CardContainer>
        <Top>
          <Heading id="translational">{subheading1}</Heading>
          <Div>
            {data1.map((item) => {
              return (
                <Card
                  onClick={() => {
                    if (item.to) navigate(item.to);
                  }}
                >
                  <ImageContainer>
                    <img src={item.image} alt="" />
                  </ImageContainer>
                  <CardHeading align={align}>{item.heading}</CardHeading>
                  <p align={align}>{item.text}</p>
                </Card>
              );
            })}
          </Div>
        </Top>
        <Bottom>
          <Heading id="market">{subheading2}</Heading>
          <Div>
            {data2.map((item) => {
              return (
                <Card
                  onClick={() => {
                    if (item.to) navigate(item.to);
                  }}
                >
                  <ImageContainer>
                    <img src={item.image} alt="" />
                  </ImageContainer>
                  <CardHeading align={align}>{item.heading}</CardHeading>
                  <p align={align}>{item.text}</p>
                </Card>
              );
            })}
          </Div>
        </Bottom>
      </CardContainer>
    </Container>
  );
}
