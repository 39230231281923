import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  margin: 5% 0;
  gap: 1.5em;
  margin-top: 4em;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 1em;
    padding: 0 5%;
  }
`;
export const LeftContainer = styled.div`
  width: 50%;
  display: flex;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const RightContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 2.5em;
  border-bottom: 1px solid var(--primary-200, #c3c4fe);
  &:last-child {
    border-bottom: none;
  }
`;
export const Heading = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-style: normal;
  font-family: Clash Grotesk Variable;
  font-weight: 500;
  line-height: normal;
`;
export const Content = styled.div`
  color: var(--neutral-500, #6b7280);
  font-feature-settings: "salt" on;
  font-size: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;
