import React, { useState } from "react";
import { CountUp } from "use-count-up";
import { motion } from "framer-motion";
import { Container, Header, SubContainer, ListContainer, Dash } from "./styles";
import ScrollTrigger from "react-scroll-trigger";

export default function SectionThree() {
  const [countOn, setCountOn] = useState(false);
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        translateY: 300,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        translateY: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
    >
      <ScrollTrigger
        onEnter={() => {
          setCountOn(true);
        }}
        onEnterBack={() => {
          setCountOn(true);
        }}
        onExit={() => {
          setCountOn(false);
        }}
      >
        <Container>
          <Header>Our figures grow by the year</Header>
          <SubContainer>
            <ListContainer>
              <p>Studies performed</p>
              <Dash />
              <span>
                {countOn && (
                  <CountUp isCounting start={0} end={50} duration={8} />
                )}
                +
              </span>
            </ListContainer>
            <ListContainer>
              <p>Labs developed</p>
              <Dash />
              <span>
                {countOn && (
                  <CountUp isCounting start={0} end={5} duration={10} />
                )}
                +
              </span>
            </ListContainer>
            <ListContainer>
              <p>Biomarkers processed</p>
              <Dash />
              <span>
                {" "}
                {countOn && (
                  <CountUp isCounting start={0} end={95} duration={8} />
                )}
                k
              </span>
            </ListContainer>
          </SubContainer>
        </Container>{" "}
      </ScrollTrigger>
    </motion.div>
  );
}
