// import { uuid } from "uuid";
// import img1 from "../images/article1.jpg";
// import img2 from "../images/article2.png";
// import img3 from "../images/article3.png";
// import img4 from "../images/article4.png";

export let insights = [
  // {
  //   id: 0,
  //   date: "Sep 26",
  //   fulldate: "9/26/23",
  //   title:
  //     "Workforce Attrition: How Digital Pathology and AI can bridge the gap in sSA",
  //   url: "./",
  //   author: "Nzube Ekpunobi, Tunde Animashaun",
  //   image: img1,
  //   imagecredit: "Precipoint",
  //   static: true,
  //   articleContent: [
  //     {
  //       heading: "",
  //       content:
  //         "Extended waiting periods and limited accessibility to medical services are two effects of the present attrition of healthcare professionals on patient care. However, modern innovations like remote review and digital pathology may provide a remedy. Through the use of these technologies, pathologists may remotely assess and diagnose cases, potentially enhancing patient access and decreasing the duration of waits.  With the exception of Botswana and South Africa, survey results from 2012 (Nelson et al., 2016; Wilson et al., 2018) showed that all SSA nations have less than one pathologist for every 500,000 people. There are typically one per million inhabitants in many nations, but there were none in Somalia at the time. The use of digital pathology and remote evaluation can help alleviate this problem in these regions. These technologies have considerable potential advantages, such as improved patient outcomes, cost savings, and enhanced productivity. But there are also certain issues that must be resolved, such the necessity for a solid infrastructure and privacy issues.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "A diagnostic field known as histopathology was developed on the basis of the photographic interpretation of cellular biology depicted in photographs. With the advent of digitalized images, pathology has developed into the phenomenon that is now known as digital pathology (DP). Telepathology is made possible by the real-time exchange of digital images and video feeds between adjacent hospitals, universities (for second opinions), teachers and pupils, and between homes and companies (home offices). Because of advancements in the rate of glass slide digitization technology and the decrease in storage costs, the usage of whole slide images (WSIs), also known as virtual slides, has significantly expanded. Similar to the Google Maps app, users of WSIs may see digital slides on electronic screens at different magnifications.  Digital pathology is the umbrella term for all related technologies that employ digital slides (WSIs) to allow workflow improvements and advancements. These include digital labelling of specimens and monitoring, dashboards and management of workflows, digital image examination, and synoptic reporting tools. They also consist of lab and picture management systems.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "The Workflow",
  //       content:
  //         "Once scanning has been completed, cases are handed to a reporting pathologist. DP allows for flexible balance of workloads or case reallocation in the event of, for instance, a sick absence. A quick initial glance to assess the image's general attributes, such as its spatial arrangement, texture, size, and colour, is followed by a short focus on regions of interest (such as a suspected carcinoma focus), according to studies on image perception. Higher resolution monitors, according to Randell et al. (2015), are helpful for more rapidly identifying these areas on low-power digital images, which are subsequently evaluated at high-power. Applications for tracking digital slide motions that are sensitive to magnification can help to ensure that minute particles are not missed (Stathonikos et al., 2020). Directly launched measurement and quantification tools from the viewer enable quick documentation of outcomes. Different stains can be spatially correlated more easily when many slides are presented side by side and connected so that they can be moved synchronously or overlaid.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "The Challenges",
  //       content:
  //         "Traditional pathology diagnosis under a microscope is quick, easy, and very economical. Pathology diagnosis is less expensive than digital pathology, which has high initial equipment expenses for scanning and viewing, as well as continuing maintenance and software expenditures. For this reason, traditional pathology expenditures are typically orders of magnitude lower than those for digital pathology. A fully digital pathology workflow would need a substantial investment in scanners, computer servers, pathologists' workstations, and medical displays.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Also,  the storage of high resolution and large sized digital images could be problematic for sites with limited on-site data storage capacity.  The advent of cloud based storage has provided some accessible/cost effective storage options and a few options exist for GDPR compliant cloud based storage such as Precicloud.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "The Benefits ",
  //       content:
  //         "The voyage of a glass slide in a pathology department that is entirely digital would come to an end after scanning in the lab. The slides may then be viewed digitally at any workstation. This reduces the amount of time pathologists must spend organizing, looking for, and moving slides, tasks that demand a pathologist's time and focus (Ho et al., 2012). A pathologist's workload may be managed more effectively thanks to a computerized workflow. The number of cases that need to be reported, the development of immunohistochemistry and special stains, and the assignment of particular cases to trainees may all be seen on digital dashboards. The pathologist might also allocate cases to agendas for tasks like teaching sets or meetings of multidisciplinary teams.  The digital pathology workstation has advantages in terms of efficiency as well as quality. Regions of interest can be easily identified on digital slides, and they can be connected to the written report. Voice recognition software can be used to create the report itself (Griffin and Treanor, 2017).",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "More than 200 instances of cervical cancer slides from LUTH and JUTH were scanned and studied over the course of two years using digital pathology and remote review, according to research by Silas et al. published in 2022. Pathologists from the partnering institutions (LUTH, NU, and JUTH) examined digital slides.  The capacity to reach a consensus on pathohistology analysis and evaluation, including tumour kind, grade, and differentiation, was listed as one of this system's benefits. 1.) Accurately measuring the tumour's size, dimension, extension, necrosis, and other pathologic features; 2.) taking high-quality screenshots for publication, education, and illustration; 3.) achieving a quick turnaround time of 3 to 5 days as opposed to a few months for inter institute slide evaluation; and (4) turning scanned virtual slides into valuable educational resources for local pathologists, researchers, and trainees who can easily access these deidentified virtual slides.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "The introduction of digital pathology and remote evaluation platform such Precipoint’s IO:M8 digital microscope and Precicloud storage platform benefits healthcare systems greatly. Even while the upfront costs of buying, setting up, and providing education for the equipment are highly capital-heavy, they cannot be compared to the long-term benefits in terms of health outcomes as well as knowledge transfer. It is possible to completely eliminate the customary, very high cost of expert training overseas, slide, FFPE block/tissue shipping.  In addition, the are cost savings as it also eliminates the need for repeat cut sections, and the potential risk of missing a margin of interest leading to delayed or wrong pathology diagnosis.  It also facilitates second opinion consultations from partnering pathologists who can access these digital images remotely. Metaphor Laboratory, through its diagnostic value programs is at the forefront of making these solutions accessible and impactful in solving Africa’s healthcare worker attrition burden.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Sequel to this is the utilization of digital images to develop AI algorithms to detect margin of interests, specific biomarkers and/or targets for further therapeutic R&D work. With our central labs in Frederick and Lagos Nigeria, we can support R&D programs ranging from IRB consented biomarker services, Tissue digitization/review services, assay design, development, validation and Genomic services. ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content: "",
  //       bulletPoints: false,
  //     },
  //   ],
  //   references: [
  //     {
  //       content:
  //         "Griffin, J and Treanor, D. (2017). Digital pathology in clinical use: where are we now and what is holding us back? Histopathology, 70 (1). pp. 134-145. ISSN 0309-0167",
  //     },
  //     {
  //       content:
  //         "Ho J, Aridor O, Parwani AV. (2012). Use of contextual inquiry to understand anatomic pathology workflow: Implications for digital pathology adoption. J Pathol Inform. 3:35.",
  //     },
  //     {
  //       content:
  //         "Nelson, A. M., Milner, D. A., Rebbeck, T. R., Iliyasu, Y. (2016). Oncologic care and pathology resources in africa: survey and recommendations. J Clin Oncol Off J Am Soc Clin Oncol.34:20–6. doi: 10.1200/JCO.2015.61.9767",
  //     },
  //     {
  //       content:
  //         "Randell, R., Ambepitiya, T., Mello-Thoms, C., Ruddle, R.A., Brettle, D., Thomas, R.G., Treanor, D.(2015). Effect of display resolution on time to diagnosis with virtual pathology slides in a systematic search task. J. Digit. Imaging, 28, 68–76.",
  //     },
  //     {
  //       content:
  //         "Stathonikos, N., Nguyen, T.Q., van Diest, P.J. (2020). Rocky road to digital diagnostics: Implementation issues and exhilarating experiences. J. Clin. Pathol..",
  //     },
  //     {
  //       content:
  //         "Wilson, M. L., Fleming, K. A., Kuti, M. A., Looi, L. M., Lago, N., Ru, K.(2018). Access to pathology and laboratory medicine services: a crucial gap. Lancet. 391:1927–1938. doi: 10.1016/S0140-6736(18)30458-6",
  //     },
  //     {
  //       content: "",
  //     },
  //   ],
  // },
  // {
  //   id: 1111111111111111111111,
  //   date: "Aug 22",
  //   fulldate: "8/22/23",
  //   title:
  //     "Low footprint, High Complexity Testing at Point of Care: Multiplexing as a Solution",
  //   url: "./",
  //   author: "Nzube Ekpunobi, Tunde Animashaun",
  //   image: img2,
  //   imagecredit: "Biomeme Inc.",
  //   static: true,
  //   articleContent: [
  //     {
  //       heading: "",
  //       content:
  //         "The future of healthcare is rapidly changing, thanks in part to advances in molecular testing, such as multiplex nucleic acid amplification tests (NAATs). Nucleic acid amplification tests (NAATs) are particularly important for organisms for which traditional tools have always been either unavailable or too expensive, such as C. trachomatis, genital mycoplasmas, and viruses. Overall, these tools overcome some important limitations of microscopy and culture-based approaches. NAATs have a significantly lower turnaround time (they can be easily automated, and the time to result is significantly lower than that of traditional approaches), they allow the detection of organisms which cannot be grown in culture, and they have a significantly higher sensitivity (a dramatically relevant advantage for asymptomatic infections where the organisms’ load is low) (Goldenberg, 2013). Furthermore, NAATs do not require viable organisms, allowing the collection and utilization of more diverse and less invasive biological samples (such as urine or vaginal swabs) which can be easily self-collected; they can be automated and standardized, and they can be performed by minimally trained people; and they can be multiplexed, allowing the detection of multiple organisms from the same sample and in a single test run (Beal et al., 2016). Due to the latest technological advances, NAATs also have significant potential to become the POC tests of the future for STIs due to a reduction in their cost (Toskin et al., 2017).",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "The World Health Organization (WHO) has estimated that annually approximately 374 million new cases of curable STIs such as syphilis, gonorrhoea, and chlamydia, occur worldwide in 2021 and sub-Saharan Africa accounts for approximately 40% of the global burden of STIs (WHO, 2016; Shewarega et al., 2022). Multiplex nucleic acid amplification tests (NAATs) are revolutionizing the diagnosis and treatment of sexually transmitted infections (STIs), reducing the burden of disease and saving lives. The multiplex NAATs work by amplifying and detecting multiple DNA or RNA targets simultaneously. This is done by using fluorescent probes that are specific to each target sequence. Once the targets are detected, the results are analyzed and interpreted. This can all be done in a single test, which makes it much more efficient and accurate than traditional testing methods. The ability to detect and treat co-infections with multiplex NAATs could have a major impact on reducing the burden of disease caused by STIs",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "One of the biggest implications of multiplex NAATs for STI diagnosis is that it can help to identify co-infections, which are when a person has more than one infection at the same time. For example, a patient may be infected with both chlamydia and gonorrhoea. If these infections are not detected and treated properly, they can lead to serious health complications. The majority of people infected with Chlamydia trachomatis/Neisseria gonorrhoeae are asymptomatic. These persons may or may not seek health care for other reasons (Wi et al., 2019). However, identifying and treating asymptomatic persons can prevent the development of STI-related complications for the individual and help reduce the transmission in the community. Nucleic acid amplification tests (NAAT) employ molecular-based technology to identify the presence of Neisseria gonorrhoea and Chlamydia trachomatisthe causative organisms of gonorrhoea and chlamydia (Tobin et al., 2020). This would reduce the time and cost of treatment, as well as the risk of complications from the infections.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "In addition, multiplex NAATs could be used to develop new diagnostic tests for emerging infectious diseases. These new diagnostic tests will allow for more accurate epidemiological monitoring of the viruses causing infections, i.e. they will help to track the spread of the virus and identify areas where outbreaks are occurring. For Instance, the development of these new diagnostic tests will help to improve the overall response to Zika virus outbreaks, allowing allow for more targeted public health interventions.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " Existing and newly formed NAAT technology are putting in place novel protocols with integrated sample preparation, faster amplification and detection methods, and innovative microfluidic designs to support point-of-care testing (POCT) for an array of infectious diseases and globally prevalent sexually transmitted infections (STIs). One such platform is the FRANKLIN currently developed by Biomeme. Multiplex NAATs are an important tool for improving the diagnosis and treatment of STIs, as well as emerging infectious diseases such as the Zika virus. They offer several benefits, including faster and more accurate diagnosis, improved epidemiological monitoring, and better public health interventions",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content: "",
  //       bulletPoints: false,
  //     },
  //   ],
  //   references: [
  //     {
  //       content:
  //         "Goldenberg, S. Molecular-based diagnostics, including future trends. Medicine 2013, 41, 663–666.",
  //     },
  //     {
  //       content:
  //         "Beal, S.G.; Assarzadegan, N.; Rand, K.H. Sample-to-result molecular infectious disease assays: Clinical implications, limitations and potential. Expert Rev. Mol. Diagn. 2016, 16, 323–341.",
  //     },
  //     {
  //       content:
  //         "Toskin, I.; Blondeel, K.; Peeling, R.W.; Deal, C.; Kiarie, J. Advancing point of care diagnostics for the control and prevention of STIs: The way forward. Sex. Transm. Infect.2017, 93, S81–S88",
  //     },
  //     {
  //       content:
  //         "Workowski KA. Centres for Disease Control and Prevention sexually transmitted diseases treatment guidelines. Clin. Infect. Dis. 2015, 61, S759–S762.",
  //     },
  //     {
  //       content:
  //         "Kejela G, Soboka B. Assessment of Knowledge, Attitude and Preventive Practices towards SexuallyTransmitted Infections among Preparatory School Students in Shone Town, Southern Ethiopia, 2014. J. Health. med. inform. 2015, 6(183), 2.",
  //     },
  //     {
  //       content:
  //         "World Health Organization. Global health sector strategy on sexually transmitted infections 2016–2021: toward ending STIs. In: World Health Organization. 2016.",
  //     },
  //     {
  //       content:
  //         "Shewarega, E.S., Fentie, E.A., Asmamaw, D.B. et al. Sexually transmitted infections related care-seeking behaviour and associated factors among reproductive age women in East Africa: a multilevel analysis of demographic and health surveys. BMC Public Health 2022 22, 1714 https://doi.org/10.1186/s12889-022-14120-w.",
  //     },
  //     {
  //       content:
  //         "Wi TE, Ndowa FJ, Ferreyra C, Kelly-Cirino C, Taylor MM, Toskin I et al. Diagnosing sexually transmitted infections in resource-constrained settings: challenges and ways forward. J Int AIDS Soc. 2019, 22, e25343.",
  //     },
  //     {
  //       content:
  //         "Tobin L, Guerra L, Ahouanvoeke L, Carpio JG, Irambona D, Nyarko EO, Macera C, Wiersma S. Is it time to use nucleic acid amplification tests for identification of persons with sexually transmitted infections?: evidence from seroprevalence and behavioural epidemiology risk surveys in men with chlamydia and gonorrhoea. Pan Afr Med J. 2020, 36, 299.",
  //     },
  //   ],
  // },
  // {
  //   id: 22222222222222222222222,
  //   date: "Aug 7",
  //   fulldate: "8/7/23",
  //   title:
  //     "Precision Diagnostics: Unleashing Healthcare Transformation in a Genetically Diverse Africa",
  //   url: "./",
  //   author: "Tunde Animashaun MPH, Adaobi Duru",
  //   image: img3,
  //   imagecredit: "",
  //   static: true,
  //   articleContent: [
  //     {
  //       heading: "",
  //       content:
  //         " Amidst the vast genetic diversity of Africa, both challenges and opportunities emerge in the realm of healthcare and diagnostics. The prevailing absence of biomarker inclusion in diagnostic methods development has led to substantial deficiencies in disease diagnosis, exacerbating misdiagnoses, delayed treatments, and a limited understanding of region-specific ailments. The urgency for precision diagnostics in Africa becomes undeniable, as it holds the potential to transcend the current healthcare landscape, enabling tailored and accurate medical interventions for its diverse populations. ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "Navigating Challenges in Diagnostic Methods",
  //       content:
  //         "Africa's genetic tapestry, replete with diversity, beckons the world of medical research. Yet, this diversity remains under-represented in global genomic databases, a stark reality unveiled in the seminal study by Popejoy and Fullerton in 2016. African ancestry constituted a mere 2.5% of participants in genomic research, a disconcerting statistic with far-reaching implications. The dearth of representation perpetuates health disparities, impeding the development of precision diagnostic tools uniquely adapted to Africa's genetic landscape, leaving countless lives vulnerable to suboptimal treatments.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Compounding the complexity, Africa bears the burden of Neglected Tropical Diseases (NTDs), affecting over 400 million lives, as declared by the World Health Organization in 2021. These diseases continue to cast a shadow over the continent, exacerbated by the lack of research and development efforts devoted to precision diagnostics. The paucity of focused research obstructs early detection and targeted treatments for NTDs, underscoring the pressing need to invest in precision diagnostics and alleviate the pervasive suffering they inflict upon African communities.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "Averting the Challenges: Precision Diagnostics Unveiled",
  //       content:
  //         "Precision diagnostics in genetically diverse Africa heralds a new dawn of hope and empowerment for healthcare providers. Armed with the means to identify ethnic biomarkers associated with diseases, precision diagnostics bestows the gift of accuracy and improved treatment outcomes. A compelling illustration lies in the realm of gynaecological cancers, where breast cancer emerges as a formidable adversary, with 70% of cases being hormone receptor positive. The determination of hormone receptor status becomes a pivotal factor in guiding the selection of endocrine therapies like tamoxifen, strategically neutralizing the impact of hormones on malignant cells.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Notably, precision diagnostics extends its reach beyond gynaecological cancers to address the harrowing prevalence of Neglected Tropical Diseases (NTDs) such as malaria, HIV, and tuberculosis. In new assay validation for example, Limits of Detection and sensitivity of an assay cannot be truly ascertained without analyzing biospecimens across demographics and ethnicities.  Expanded target validation ensures the reliability, sensitivity and specificity  of diagnostic tests, empowering healthcare providers to deliver accurate diagnoses, timely interventions, and improved patient outcomes. Such validation plans are becoming even more relevant for emerging threats. Metaphor Laboratory is facilitating early detection and precision diagnostics by partnering in the development and validation of assays and technologies.  ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Similarly, Companion diagnostics or CDx emerges as a powerful ally for new oncology therapies.  This new approach uses specific biomarkers to determine beneficiaries for a certain drug or therapy and rules out non-beneficiaries for unnecessary and sometimes debilitating treatment options.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "Embracing a Future of Healthcare Transformation:",
  //       content:
  //         "Precision diagnostics and CDx arms healthcare providers with the capacity to mitigate the suffering imposed by NTDs and Cancer on the African population.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Developing quality certified laboratory infrastructure is one of the 4 pillars of Metaphor Laboratory’s mission towards achieving this.    This creates the enabling foundation for global research participation. Working in collaboration with government agencies/regulators, ethical committees,  and the biotech ecosystem,  we are helping to build the next era of healthcare in sub- Saharan Africa.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "In summary, Africa embraces the power of precision medicine as diagnostics presents a low hanging fruit towards sustainable health.   For this to be achieved,  priority has to be given to modalities that will reduce hospital visits i.e. early and precise diagnostics which further relies on reliables assays and technologies developed in quality certified labs.  ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Metaphor Laboratory welcomes biotech development in our combined 10,000 sq/ft laboratory in Lagos and Frederick with access to thousands of ethnically diverse and ethically sourced biomarkers.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content: "",
  //       bulletPoints: false,
  //     },
  //   ],
  //   references: [
  //     {
  //       content:
  //         "1. Popejoy, A. B., & Fullerton, S. M. (2016). Genomics is failing on diversity. Nature, 538(7624), 161-164. doi:10.1038/538161a.",
  //     },
  //     {
  //       content:
  //         "2. World Health Organization (WHO). (2021). Neglected Tropical Diseases. Retrieved from https://www.who.int/teams/control-of-neglected-tropical-diseases/neglected-tropical-diseases.",
  //     },
  //   ],
  // },
  // {
  //   id: 333333333333333333333,
  //   date: "Jul 8",
  //   fulldate: "7/8/23",
  //   title: "Diagnostics Check Up in Nigeria Vol 1",
  //   url: "./",
  //   author: "Nzube Ekpunobi",
  //   image: img4,
  //   imagecredit: "",
  //   static: true,
  //   articleContent: [
  //     {
  //       heading: "",
  //       content:
  //         "There has been an increased focus on health improvement and system strengthening as a result of recent epidemics across the globe. However, despite the limited progress that has been made, infectious diseases still place a significant strain on Nigeria's health system. The WHO noted that Nigeria's health outcome indicators are still unacceptably high and that there is a significant discrepancy in health status between the various states and geographical zones, with a clear rural-urban split, in its annual report for 2021. Malaria, HIV, Tuberculosis, Cholera, Lassa Fever, Yellow Fever, Meningococcal Meningitis, and other infectious diseases continue to wreak havoc on the nation.  ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " According to the NAIIS-2018, Nigeria has the fourth-highest HIV burden in the world with an estimated 1.8 million people living with the illness. According to the Global AIDS Monitoring - GAM 2020, 95% of these patients are currently aware of their status, 96% are receiving ART, and 89% of ART recipients have viral suppression. Additionally, malaria is still a major source of morbidity and mortality in Nigeria. According to the World Malaria Report 2021, Nigeria is responsible for 27% and 32% of the predicted global malaria cases and fatalities.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " Calls for the declaration of a national health emergency by the NCDC were made in 2020 as a result of Lassa fever, which had spread to 27 States and resulted in 689 confirmed cases and 118 fatalities as of February 2020, compared to 381 confirmed cases and 83 fatalities for the same period of time in February 2019. With a cumulative total of 111,062 and 3,604 suspected cases and deaths in 2021, Nigeria also suffered one of its worst cholera epidemics.",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " In addition, after a period of 21 years without an outbreak, Yellow Fever returned on September 12th, 2017, in Ifelodun Local Government Area (LGA) of Kwara State, Nigeria, with an index case, a 7-year-old boy who arrived with fever, jaundice, and blood vomiting but had no history of travel or immunization. The total number of suspected cases increased to 337 at the end of the year, with a case mortality rate of 13.6% and a suspected case prevalence rate of 23.3%. Four States (Kwara, Kogi, Kano, and Zamfara) had already received confirmation at this point (Soyemi, 2021).",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "To combat the outbreaks and end the endemicity of these infectious diseases, many tactics have been suggested and implemented, including surveillance by active case search and entomological surveys, sample collection for laboratory testing, immunization and vaccination, and sample collection for laboratory testing.  These strategies have, however, failed to address the menace caused by these diseases and there has been a continuous re-emergence. ",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " The WHO in its 2021 annual report noted some major challenges that have hitherto been met in the bid to address the issue of infectious diseases. They include:",
  //       bulletPoints: false,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " Inadequate and suboptimal functionality of the WHO Recommended Rapid Diagnostic tests (e.g. GeneXpert MTB/RIF machines, limiting access to diagnosis and treatment).",
  //       bulletPoints: true,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " Suboptimal specimen referral system for moving specimens to sites for diagnosis.",
  //       bulletPoints: true,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         "Deficient management capacities of available human resources at the sub-national level.   ",
  //       bulletPoints: true,
  //     },
  //     {
  //       heading: "",
  //       content: "Data Paucity for decision-making",
  //       bulletPoints: true,
  //     },
  //     {
  //       heading: "",
  //       content:
  //         " The gap in early diagnosis continues to pose a major challenge in addressing the menace caused by these infectious diseases and thus there is a need for the creation of certified diagnostic centres across the various states to enable early detection and commence treatment. Also, there is a need for the establishment of an electronic laboratory reporting system used for all reportable conditions to transfer data to the local and/or state health department in Nigeria. This would help inform public health surveillance and can be used to help in disease prevention and the determination of control measures.",
  //       bulletPoints: false,
  //     },
  //   ],
  //   references: [
  //     {
  //       content:
  //         "1. Nigeria Centre for Disease Control. Epidemiological Report.a.",
  //     },
  //     {
  //       content: "2. World Health Organization. World Malaria Report. 2021",
  //     },
  //     {
  //       content:
  //         "3. The 2018 Nigeria HIV/AIDS Indicator and Impact Survey (NAIIS)",
  //     },
  //     {
  //       content:
  //         "4. Soyemi, T.  (2021). Neglect of common infectious disease outbreaks during the COVID-19 pandemic: an impending crisis in Nigeria? African Journal of Clinical and Experimental Microbiology. 22 (2): 113-116.",
  //     },
  //   ],
  // },
];
