import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import frame from "../../assets/images/Frame 38.png";
import {
  Container,
  LeftContainer,
  Section,
  Heading,
  Content,
  RightContainer,
} from "./styles";

export default function About() {
  return (
    <>
      <Header />
      <Container>
        <LeftContainer>
          <Section>
            <Heading>Our Mission</Heading>
            <Content>
              To prevent disease and deaths due to delayed or mis-diagnosis by
              increasing the amount of clinical laboratories in sub-Saharan
              Africa that meet global standards.
            </Content>
          </Section>
          <Section>
            <Heading>Who we are</Heading>
            <Content>
              Metaphor is a diagnostics focused Contract Research
              Organization(CRO) that leverages the need for clinical diverse
              biomarkers to offer Clinical Research as a Care Option in
              sub-SaharanAfrica. We partner with CROs, biotechnology firms, and
              academic research institutions globally to develop and validate
              novel assays/technologies to detect cancers and infectious
              diseases early in Sub-Saharan Africa under locally approved IRB
              protocols.
            </Content>
          </Section>
          <Section>
            <Heading>What we do</Heading>
            <Content>
              We develop high quality clinical laboratories in sSA that can
              support global clients in the areas of virtual diagnostics related
              staffing, training and consulting, group purchasing of
              instrumentation, regents/supplies, Translational Research and
              Bio-specimen solutions. Our clients range from large school
              networks to Biopharmaceutical and Biotechnology firms in the USA
              and sSA.
            </Content>
          </Section>
        </LeftContainer>
        <RightContainer>
          <img src={frame} alt="" />
        </RightContainer>
      </Container>
      <Footer />
    </>
  );
}
