import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0em ${(props) => props.padding};
  margin-top: ${(props) => props.margintop};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    gap: 0em;
    padding: 0 5%;
    margin-top: ${(props) => props.mobilemargintop};

    padding-top: 3em;
    padding-bottom: 6em;
    flex-direction: column;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  width: 50%;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  p {
    width: 100%;
    color: var(--neutral-500, #6b7280);
    font-feature-settings: "salt" on;

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
  img {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    left: 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    margin-bottom: 2em;
    img {
      width: 3.80481rem;
      height: 3.80481rem;
    }
  }
`;

export const Heading = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-style: normal;
  font-family: Clash Grotesk Variable;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  margin: 1em 0;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    color: var(--neutral-800, #1f2937);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const EducationContainer = styled.div`
  width: 100%;
  color: var(--neutral-800, #1f2937);
  font-size: 1.25rem;
  font-family: Clash Grotesk Variable;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
  }
`;
export const EducationSubContainer = styled.div`
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  div {
    display: flex;
    align-items: center;

    div {
      padding: 0.5em 1em;
      border-radius: 1rem;
      margin: 0.5em 0;
    }
  }
  img {
    width: 100%;
    height: 10rem;
    margin-right: 0.5em;
    border-radius: 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6em;
      width: 100%;
    }
  }
`;
export const QR = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 6rem;
    height: 6rem;
    margin-right: 0.5em;
    border-radius: 0;
  }
`;
export const ExperienceContainer = styled.div`
  width: 100%;
  color: var(--neutral-800, #1f2937);
  font-size: 1.25rem;
  font-style: normal;
  font-family: Clash Grotesk Variable;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
  }
`;
export const ExperienceSubContainer = styled.div`
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1em;
  div {
    display: flex;
    align-items: center;

    div {
      margin: 0.5em 0;
      padding: 0.5em 1em;
      border-radius: 1rem;
    }
  }
  img {
    width: 100%;
    height: 10rem;
    margin-right: 0.5em;
    border-radius: 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6em 0.3em;
      width: 100%;
    }
  }
`;
