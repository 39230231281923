import styled from "styled-components";

export const Container = styled.div`
  margin-top: 10em;
  padding: 0 10%;
  display: flex;
  padding-bottom: 6em;
  flex-direction: column;
  gap: 1em;
  @media (max-width: 768px) {
    display: flex;
    padding: 2em 5%;
    flex-direction: column;
    margin-top: 0em;
  }
`;
export const DateContainer = styled.div`
  display: flex;
  gap: 0.4em;
  justify-content: flex-start;
  align-items: center;
  p {
    color: var(--neutral-600, #4b5563);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 155.556% */
  }
  @media (max-width: 768px) {
    p {
      font-family: Clash Grotesk Variable;
      font-size: 0.91313rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
export const Title = styled.h1`
  color: var(--neutral-800, #1f2937);
  width: 80%;
  font-size: 3rem;
  font-family: Clash Grotesk Variable;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 4.8rem */
  @media (max-width: 768px) {
    color: var(--neutral-800, #1f2937);
    font-family: Clash Grotesk Variable;
    width: 100%;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  img {
  }
`;
export const Credit = styled.p`
  color: #000;
  font-feature-settings: "salt" on;

  /* Paragraph/Medium/Regular */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;
export const ContentContainer = styled.div``;
export const Content = styled.div`
  h1 {
    color: var(--neutral-800, #1f2937);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 2.5em;
  }

  p {
    color: var(--neutral-500, #6b7280);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
`;
export const ReferenceContainer = styled.div`
  margin-bottom: 2em;
  h1 {
    color: var(--neutral-800, #1f2937);
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 140% */
    letter-spacing: -0.025rem;
    text-align: left;
  }
`;
export const Reference = styled.p`

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  p{
    color: var(--neutral-500, #6B7280);
  }
`;
export const NextContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 1em; */
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    div {
      justify-content: space-between;
      /* gap: 0.5em; */
    }
  }
`;
export const Arrow = styled.div`
  width: 3rem;
  height: 3rem;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    width: 1.5em;
    height: 1.5em;
  }
`;
export const ReadButton = styled.div`
  display: flex;
  color: var(--neutral-900, #111827);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.875rem 4em;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
  border-radius: 2rem;
  border: 1px solid #10689b;
  background: var(--generic-white, #fff);
  position: absolute;
  left: 1.5em;
  bottom: 2em;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    width: fit-content;
    display: flex;
    font-size: 0.751313em;
    padding: 0.4em 1.5em;
  }
`;
export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  padding-bottom: 4.5rem;
  @media (max-width: 768px) {
    gap: 0em;
  }
`;
export const NextTitle = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-style: normal;
  font-family: Clash Grotesk Variable;
  font-weight: 500;
  line-height: normal;
   p{
   color: var(--neutral-800, #1F2937);
  }
  @media (max-width: 768px) {
    color: var(--neutral-800, #1f2937);
    font-family: Clash Grotesk Variable;
    width: 100%;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
