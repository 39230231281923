import styled from "styled-components";

export const Container = styled.div`
  padding: 6em 4em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    margin: 0 5%;
    padding: 0em;
    padding-bottom: 2.5em;
  }
`;
export const Header = styled.div`
  width: 100%;
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  font-family: Clash Grotesk Variable;
  line-height: normal;
  margin-bottom: 2em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--neutral-800, #1f2937);
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 80%;
    margin: 1em auto;
  }
`;
export const SubContainer = styled.div`
  display: flex;
  padding: 3.5rem;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: #10689b;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    border-radius: 0em;
    width: 100%;
    padding: 3rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    display: flex;
    padding: 2.5em;
  }
`;
export const ListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-self: stretch;
  p {
    color: #ace1ff;
    font-size: 1.6rem;
    font-style: normal;
    font-family: Clash Grotesk Variable;
    font-weight: 500;
    line-height: normal;
  }
  span {
    color: var(--neutral-50, #f9fafb);
    font-size: 6rem;
    font-style: normal;
    font-weight: 500;
    font-family: Clash Grotesk Variable;
    line-height: normal;
    /* margin-left: 1rem; */
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    p {
      width: 100%;
      color: #ace1ff;
      font-family: Clash Grotesk Variable;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    span {
      color: var(--neutral-50, #f9fafb);
      font-family: Clash Grotesk Variable;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
export const Dash = styled.div`
  width: 35rem;
  height: 0.0625rem;
  background: #009df8;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
  }
`;
