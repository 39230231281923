import React from "react";
import hero from "../../../assets/images/D Asset.png";
import frame from "../../../assets/images/Who-we-are.jpeg";
import stat from "../../../assets/images/Frame 427320668.svg";
import { motion } from "framer-motion";
import {
  Container,
  HeroImage,
  HeroText,
  ContactUs,
  SubContainer,
  LeftContainer,
  ImgContainer,
  RightContainer,
} from "./styles";

export default function SectionOne() {
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        // translateY: 50,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        // translateY: 0,
      }}
      transition={{
        duration: 1.5,
        delay: 0.5,
      }}
      viewport={{ once: true }}
    >
      <Container>
        <HeroImage>
          <img src={hero} alt="" />
        </HeroImage>
        <HeroText className="grotesk">
          <ul>
            <li>we think science</li>
            <li>we deliver results</li>
          </ul>
        </HeroText>
        <ContactUs href="/#contact">Contact Us</ContactUs>
        <SubContainer>
          <LeftContainer>
            <div>who we are</div>
            <p>
              We were inspired to replicate world class diagnostics as a
              building block to quality healthcare in sub-Saharan Africa (sSA).
              We are a group of ambitious people with tons of progressive
              experience across bio-strategy and operations from the world’s
              finest healthcare institutions.
            </p>
            <ImgContainer>
              <img src={frame} alt="" />
            </ImgContainer>
          </LeftContainer>
          <RightContainer>
            <img src={stat} alt="Statistics" />
          </RightContainer>
        </SubContainer>
      </Container>
    </motion.div>
  );
}
