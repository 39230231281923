import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useParams } from "react-router-dom";
import { insights } from "../../assets/data/insights";
import left from "../../assets/images/ooui_next-ltr.svg";
import right from "../../assets/images/ooui_next-rtl.svg";
import { Helmet } from "react-helmet";
import {
  Container,
  DateContainer,
  Title,
  ImageContainer,
  Credit,
  ContentContainer,
  Content,
  ReferenceContainer,
  Reference,
  NextContainer,
  Arrow,
  TitleContainer,
  NextTitle,
  ReadButton,
} from "./styles";

export default function Read() {
  let uuid = useParams().uuid;
  const insight = insights.filter((insight) => insight.id.toString() === uuid);
  const data = insight[0];
  const [post, setPost] = useState(null);

  const previousData = insights.filter(
    (insight) => insight?.id?.toString() === getPreviousInsightId()
  );
  const nextData = insights.filter(
    (insight) => insight?.id?.toString() === getNextInsightId()
  );

  function getPreviousInsightId(currentId = insight[0]?.id.toString()) {
    const currentIndex = insights.findIndex(
      (insight) => insight?.id?.toString() === currentId
    );
    if (currentIndex === 0) {
      return null;
    } else {
      return insights[currentIndex - 1]?.id.toString();
    }
  }
  function getNextInsightId(currentId = insight[0]?.id.toString()) {
    const currentIndex = insights.findIndex(
      (insight) => insight?.id?.toString() === currentId
    );
    if (currentIndex === insights.length - 1) {
      return null;
    } else {
      return insights[currentIndex + 1].id.toString();
    }
  }

  const updateMetaTags = (data) => {
    return (
      <Helmet>
        <title>{data?.title || ""}</title>
        <meta name="description" content={data?.description || ""} />
        <meta property="og:title" content={data?.title || ""} />
        <meta property="og:description" content={data?.description || ""} />

        <meta property="og:image" content={data?.og_image[0]?.url || ""} />
        <meta
          property="og:image:width"
          content={data?.og_image[0]?.width?.toString() || ""}
        />
        <meta
          property="og:image:height"
          content={data?.og_image[0]?.height?.toString() || ""}
        />
        <meta
          property="og:image:type"
          content={data?.og_image[0]?.type || ""}
        />

        <meta name="author" content={data?.author || ""} />
        <meta property="article:author" content={data?.author || ""} />
        <meta
          property="article:published_time"
          content={data?.article_published_time || ""}
        />
        <meta
          property="article:modified_time"
          content={data?.article_modified_time || ""}
        />
      </Helmet>
    );
  };

  useEffect(
    () => {
      const fetchPost = async () => {
        try {
          const response = await fetch(
            `https://metaphorlaboratory.com/wp-json/wp/v2/posts/${uuid}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const postData = await response.json();
          //console.log("postData:", postData);

          setPost(postData);
        } catch (error) {
          console.error("Error fetching WordPress post:", error);
        }
      };

      fetchPost();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {post && updateMetaTags(post?.yoast_head_json)}
      <Header />
      <motion.div
        initial={{
          opacity: 0,
          // scale: 0.8,
          translateY: 300,
        }}
        whileInView={{
          opacity: 1,
          // scale: 1,
          translateY: 0,
        }}
        transition={{
          duration: 0.5,
          delay: 0,
        }}
      >
        {data?.static ? (
          <Container>
            <DateContainer>
              <p>{data.date}</p> &bull; <p>{data.author}</p>
            </DateContainer>
            <Title>{data.title}</Title>
            <ImageContainer>
              <img src={data.image} alt="" />
              {data.imagecredit ? (
                <Credit>Image credit: {data.imagecredit}</Credit>
              ) : null}
            </ImageContainer>
            <ContentContainer>
              {data?.articleContent.map((content) => {
                return (
                  <Content>
                    <h1>{content.heading}</h1>

                    <p>
                      {" "}
                      {content.bulletPoints ? "• " : null}
                      {content.content}
                    </p>
                  </Content>
                );
              })}
            </ContentContainer>
            <ReferenceContainer>
              <h1>References</h1>
              {data?.references.map((reference) => {
                return (
                  <Reference>
                    <p>{reference.content}</p>
                  </Reference>
                );
              })}
            </ReferenceContainer>

            <NextContainer>
              {previousData[0]?.title ? (
                <div>
                  <Link to={`/insights/${getPreviousInsightId()}`}>
                    <Arrow>
                      <img src={right} alt="" />
                    </Arrow>
                  </Link>
                  <TitleContainer>
                    <NextTitle>
                      {" "}
                      <p>{previousData[0]?.title}</p>
                    </NextTitle>
                    <Link to={`/insights/${getPreviousInsightId()}`}>
                      <ReadButton>Read</ReadButton>
                    </Link>
                  </TitleContainer>
                </div>
              ) : null}
              {nextData[0]?.title ? (
                <div>
                  <TitleContainer>
                    <NextTitle>
                      <p>{nextData[0]?.title}</p>
                    </NextTitle>
                    <Link to={`/insights/${getNextInsightId()}`}>
                      <ReadButton>Read</ReadButton>
                    </Link>
                  </TitleContainer>
                  <Link to={`/insights/${getNextInsightId()}`}>
                    <Arrow>
                      <img src={left} alt="" />
                    </Arrow>
                  </Link>
                </div>
              ) : null}
            </NextContainer>
          </Container>
        ) : (
          <div className="wordpress-post-container">
            {post && (
              <div>
                <h1>{post.title.rendered}</h1>
                <div
                  className="wordpress-post-content"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              </div>
            )}
          </div>
        )}
      </motion.div>

      <Footer />
    </>
  );
}
