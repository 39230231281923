import styled from "styled-components";

export const Container = styled.div`
  padding: 0em 10%;
  display: flex;

  margin-top: 4.25em;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    padding: 2em 5%;
    margin-top: 0em;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  gap: 1.5em;
  justify-content: space-between;
  margin-bottom: 4.5em;

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    flex-direction: column;
  }
`;
export const Problem = styled.div`
  width: 50%;
  flex-direction: column;
  display: flex;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  h1 {
    color: var(--neutral-800, #1f2937);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    color: var(--neutral-500, #6b7280);
    font-feature-settings: "salt" on;
    margin-top: -1em;

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
  }
`;
export const Data = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  h1 {
    color: var(--neutral-800, #1f2937);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    color: var(--neutral-500, #6b7280);
    margin-top: -1em;
    font-feature-settings: "salt" on;

    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
  }
`;
export const Table = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  margin-bottom: 2.6em;
  img {
    width: 100%;
  }
`;
export const References = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  margin-bottom: 4.5em;
  p {
    color: var(--neutral-500, #6b7280);
    font-feature-settings: "salt" on;

    /* Paragraph/Medium/Regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;
export const EndSection = styled.div`
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    /* display: flex; */
    /* justify-content: center; */
  }
`;
