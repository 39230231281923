import React from "react";
import Vacination from "../../../assets/images/15.png";
import Thermometer from "../../../assets/images/18.png";
import material from "../../../assets/images/16.png";
import Medicine from "../../../assets/images/17.png";
import hospital from "../../../assets/images/10.png";
import Tablet from "../../../assets/images/12.png";
import Care from "../../../assets/images/14.png";
import money from "../../../assets/images/13.png";
import { BlueBoard } from "../../blueboard/BlueBoard";
import { motion } from "framer-motion";

export default function SectionTwo() {
  const data1 = [
    {
      image: Vacination,
      heading: "Biomarker Solutions",
      text: "Variant rich, highly annotated bio makers across clinical indications to support Research & Development",
      to: "/biomarker-solutions",
    },
    {
      image: Thermometer,
      heading: "Assay Development",
      text: "Design and development of assays for target molecule detection. Optimization and validation for LDT or 510k approval pathway",
      to: "/assay-development",
    },
    {
      image: material,
      heading: "Genomic Science",
      text: "DNA extraction, library preparation, sequencing and  interpretation to detect mutations and tumor markers early",
      to: "/genomic-science",
    },
    {
      image: Medicine,
      heading: "Digital Health",
      text: "Health technology platform to detect and track biomarkers longitudinally and across demographics",
      to: "/digital-health",
    },
  ];
  const data2 = [
    {
      image: hospital,
      heading: "Site & Capacity Development",
      text: "Quality Certified Specialty labs, Scientists and Program Management. Design and build-out ofGMP/GLP compliant infrastructure.",
      to: "/site-and-capacity-development",
    },
    {
      image: Tablet,
      heading: "Insights & Surveys",
      text: "Market knowledge and advisory based on data and trends. Due  diligence and safeguard strategies.",
      to: "/insights-and-surveys",
    },
    {
      image: money,
      heading: "Pricing & Reimbursement",
      text: "Analysis of predicate device performance to determine competitive price point and  affordability. Reimbursement and cost recovery strategies to justify market entry",
      to: "/pricing-and-reimbursement",
    },
    {
      image: Care,
      heading: "Alliance Management",
      text: "Government relations and stakeholder engagement. Ethical approvals, partner sites election and initiation, public-private partnership model",
      to: "/alliance-management",
    },
  ];
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        translateY: 300,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        translateY: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
    >
      <BlueBoard
        data1={data1}
        data2={data2}
        margin={"5%"}
        heading={"what we do"}
        subheading1={"Translational Science"}
        subheading2={"Market Access"}
        align={"left"}
      />
    </motion.div>
  );
}
