import Header from "../../components/header";

import Footer from "../../components/footer";

const AllianceManagement = () => {
  return (
    <>
      <Header />

      <h2>Alliance Management</h2>

      <Footer />
    </>
  );
};

export default AllianceManagement;
