import Header from "../../components/header";

import Footer from "../../components/footer";

const InsightsAndSurveys = () => {
  return (
    <>
      <Header />

      <h2>Insights and Surveys</h2>

      <Footer />
    </>
  );
};

export default InsightsAndSurveys;
