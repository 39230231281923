import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 2em 5%;
  position: relative;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    padding: 0 5%;
    padding-top: 3.81em;
    padding-bottom: 6em;
  }
`;
export const HeroImage = styled.div`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    height: 100%;
  }
`;
export const HeroText = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-family: Clash Grotesk Variable;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    margin-bottom: 1em;
    width: 100%;
    color: var(--neutral-800, #1f2937);
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
