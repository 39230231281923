import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./pages/home/index";
import About from "./pages/about/index";
import Leadership from "./pages/leadership/index";
import Insight from "./pages/insights/index";
import Value from "./pages/value/index";
import Read from "./pages/readmore";
import ScrollToTop from "./components/utility/scrollToTop";
import BiomarkerSolution from "./pages/biomarker-solutions";
import AssayDevelopment from "./pages/assay-development";
import GenomicScience from "./pages/genomic-science";
import DigitalHealth from "./pages/digital-health";
import AllianceManagement from "./pages/alliance-management";
import PricingAndReimbursement from "./pages/pricing-and-reimbursement";
import InsightsAndSurveys from "./pages/insights-and-surveys";
import SiteAndCapacityDevelopment from "./pages/site-and-capacity-development";

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/value" element={<Value />} />
        <Route path="/insights" element={<Insight />} />
        <Route path="/insights/:uuid" element={<Read />} />
        {/* Translational science routes */}
        <Route path="/biomarker-solutions" element={<BiomarkerSolution />} />
        <Route path="/assay-development" element={<AssayDevelopment />} />
        <Route path="/genomic-science" element={<GenomicScience />} />
        <Route path="/digital-health" element={<DigitalHealth />} />
        {/* Market access routes */}
        <Route
          path="/site-and-capacity-development"
          element={<SiteAndCapacityDevelopment />}
        />
        <Route path="/insights-and-surveys" element={<InsightsAndSurveys />} />
        <Route
          path="/pricing-and-reimbursement"
          element={<PricingAndReimbursement />}
        />
        <Route path="/alliance-management" element={<AllianceManagement />} />
      </Routes>
    </BrowserRouter>
  );
}
