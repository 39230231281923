import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 4em 10%;
  gap: 2rem;
  background: var(--neutral-900, #111827);
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    flex-direction: column;
  }
`;
export const ColOne = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--neutral-50, #f9fafb);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem; /* 150% */
  p {
    color: var(--neutral-50, #f9fafb);
    font-weight: 400;
    cursor: pointer;
  }
  h3 {
    font-weight: 600;
  }
`;
export const ColTwo = styled.div`
  display: flex;
  flex-direction: column; color: var(--neutral-50, #f9fafb);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem; /* 150% */
}
p {
  cursor: pointer;
  color: var(--neutral-50, #f9fafb);  
    font-weight: 400;
    a{
      color: #f9fafb;
    }
  }
h3 {
 
  font-weight: 600;
`;
export const ColThree = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 21.70588rem;
    margin: 15px 0;
    margin-left: -2px;
    border-radius: 0.5rem;
  }
  p {
    color: var(--primary-50, #f5f5ff);

    /* Text md/Normal */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
`;
