
import { GlobalStyles } from "./styles/global";
import { ThemeProvider } from "styled-components";
import Router from "./router";


const theme = {
  colors:{
    text: "#000000",
    light_text: "rgba(160, 36, 36, 0.829)",
    bg: "#ffffff",
    footer: "#000000",
  },
  mobile: {
    xxs: "425px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1536px",
  },
};


function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles/>
      <Router/>
    </ThemeProvider>
  );
}

export default App;
