import { styled } from "styled-components";

export const Container = styled.div`
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 1.625rem 7.5rem;
  align-items: center;
  background: #fff;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.mobile.lg}) {
    display: flex;
    width: 100%;
    padding: 1.625rem 1rem;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: flex;
    width: 100%;
    padding: 1.625rem 1rem;
    align-items: center;
    justify-content: space-between;
  }
`;
export const LogoContainer = styled.div`
  width: 21.70588rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 90%;
  }
  @media (max-width: ${({ theme }) => theme.mobile.lg}) {
    width: 16.85294rem;
    height: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 10.85294rem;
    height: 1.5rem;
  }
`;
export const Nav = styled.div`
  width: 33.5em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: none;
  }
`;
export const LinkDiv = styled.div`
  position: relative;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
  padding-bottom: 0.5rem;
  cursor: pointer;
  border-bottom: none;
  width: 100%;
  text-align: center;
  a {
    color: var(--neutral-900, #111827);
  }
  a.active {
    color: #10689b;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    border-bottom: 2px solid #11182713;
    text-align: left;
  }
`;
export const Button = styled.a`
  display: flex;
  padding: 0.9375rem 2.125rem 1rem 2.125rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: #10689b;
  color: #fff;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    opacity: 0.8;
    transition: 1s;
  }
  @media (max-width: ${({ theme }) => theme.mobile.lg}) {
    padding: 0.9375rem 2em;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: none;
  }
`;
export const HambugerMenu = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
export const Close = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
export const MenuBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
`;
export const MenuContainer = styled.div`
  position: absolute;
  width: 85%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  background: #fff;
  /* border-radius: 0.5rem; */
  padding: 5rem 2em;
  gap: 2rem;
  animation: slideIn 0.5s ease-in-out;

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
`;
export const DropdownContainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  text-align: center;
  top: 1.8em;
  background-color: #ffffff;
  border: 1px solid #11182713;
  left: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 1.5rem;

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    text-align: left;
  }
`;
export const DropdownContent = styled.div`
position: relative;
  text-align: left;
  width: 100%;
  font-size: 0.85em;
  z-index: 1;
  padding: 1em 0;
  border-bottom: 1px solid #11182713;

  a {
    color: var(--neutral-900, #111827);
  }
  a.active {
    color: #10689b;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    top: 0;
  }
`;
export const DropdownSideContainer = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  text-align: center;
  top: 0;
  background-color: #ffffff;
  border: 1px solid #11182713;
  left: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding:0em 1.5rem ;

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    text-align: left;
  }
`;
export const DropdownSideContent = styled.div`
  text-align: left;
  width: 100%;
  font-size: 0.85em;
  z-index: 1;
  margin: 1em 0em;
  /* border-bottom: 1px solid #11182713; */

  a {
    color: var(--neutral-900, #111827);
  }
  a.active {
    color: #10689b;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    top: 0;
  }
`;
