import styled from "styled-components";

export const CardWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  padding: 1.5em 2em;
  margin-bottom: 1.56em;
  height: fit-content;
  @media (max-width: 768px) {
    /* height:100%; */
  }
`;
export const CardHeader = styled.div`
  color: var(--neutral-800, #1f2937);
  font-feature-settings: "salt" on;

  /* Paragraph/Medium/Regular */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  margin-bottom: 1em;
`;
export const CardBody = styled.div`
  color: var(--neutral-800, #1f2937);
  font-family: Clash Grotesk Variable;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2.5em;
  cursor: pointer;
 
`;
export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ReadButton = styled.button`
  display: flex;
  width: 10.9375rem;
  padding: 0.875rem 0rem 0.9375rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  border: 1px solid #10689b;
  background: var(--generic-white, #fff);
  color: var(--neutral-900, #111827);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: normal;

  &:hover {
    background-color: #111827;
    transition: 0.5s;
    color: #fff;
  }
`;
