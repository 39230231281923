import React from "react";
import frame from "../../../assets/images/Frame 39.png";
import { motion } from "framer-motion";
import { Container, HeroText, HeroImage } from "./styles";

export default function SectionFour() {
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        translateY: 300,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        translateY: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
    >
      <Container>
        <HeroText>why we do it</HeroText>
        <HeroImage>
          <img src={frame} alt="" />
        </HeroImage>
      </Container>
    </motion.div>
  );
}
