import styled from "styled-components";

export const Container = styled.div`
  padding: 0em 10%;
  margin-top: 4em;
  display: grid;
  grid-gap: 1.75em;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  @media (max-width: 768px) {
    display: flex;
    padding: 2em 5%;
    flex-direction: column;
    margin-top: 0em;
    gap: 1em;
  }
`;
