import Header from "../../components/header";

import Footer from "../../components/footer";
import SectionOne from "../../components/homecomponents/homepageSectionOne";
import ContactUs from "../../components/homecomponents/homepageContant";
import SectionThree from "../../components/homecomponents/homepageSectionThree";
import SectionTwo from "../../components/homecomponents/homepageSectionTwo";
import SectionFour from "../../components/homecomponents/homepageSectionFour";
import { useRef } from "react";

const Home = () => {
  const contact = useRef(null);
  return (
    <>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <ContactUs contact={contact} />
      <Footer />
    </>
  );
};

export default Home;
