import Header from "../../components/header";

import Footer from "../../components/footer";

const PricingAndReimbursement = () => {
  return (
    <>
      <Header />

      <h2>Pricing and Reimbursement</h2>

      <Footer />
    </>
  );
};

export default PricingAndReimbursement;
