import Header from "../../components/header";

import Footer from "../../components/footer";

const SiteAndCapacityDevelopment = () => {
  return (
    <>
      <Header />

      <h2>Site and Capacity Development</h2>

      <Footer />
    </>
  );
};

export default SiteAndCapacityDevelopment;
