import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Card from "../../components/card/card";
import { insights } from "../../assets/data/insights";
import { motion } from "framer-motion";
import { Container } from "./styles";

export default function Insights() {
  let [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    try {
      const response = await fetch(
        "https://metaphorlaboratory.com/wp-json/wp/v2/posts"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      let data = await response.json();

      if (response.ok) {
        setPosts(data);
        setTimeout(2000, () => console.log(posts));

        data.forEach((item) => {
          if (insights.find((insight) => insight.id === item.id)) {
            return;
          }

          insights.push({
            id: item.id,
            date: item.date,
            title: item.title.rendered,
            fulldate: item.date.split("T")[0],
            content: item.content.rendered,
          });
        });
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(
    () => {
      fetchPosts();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <Header />
      <motion.div
        initial={{
          opacity: 0,
          // scale: 0.8,
          translateY: 300,
        }}
        whileInView={{
          opacity: 1,
          // scale: 1,
          translateY: 0,
        }}
        transition={{
          duration: 0.5,
          delay: 0,
        }}
      >
        <Container>
          {insights.map((insight) => {
            return <Card key={insight.id} insight={insight} />;
          })}
        </Container>
      </motion.div>
      <Footer />
    </>
  );
}
