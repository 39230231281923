import Logo from "../../assets/images/logo.png";
import menu from "../../assets/images/bx_menu.svg";
import close from "../../assets/images/Black_close_x.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  LogoContainer,
  Nav,
  LinkDiv,
  MenuBackdrop,
  MenuContainer,
  Close,
  HambugerMenu,
  DropdownContainer,
  DropdownContent,
  DropdownSideContainer,
  DropdownSideContent,
} from "./styles";

const Header = (contact) => {
  const [isMenu, setIsMenu] = useState(false);
  const [isAboutHover, setIsAboutHover] = useState(false);
  const [isServicesHover, setIsServicesHover] = useState(false);
  const [isWhat, setIsWhat] = useState(false);
  const [isTransHover, setIsTransHover] = useState(false);
  const [isMarketHover, setIsMarketHover] = useState(false);
  const [isInsightsHover, setIsInsightsHover] = useState(false);
  const location = window.location.pathname;

  // const button = useRef(null);

  // function doubleClickButton(e) {
  //   console.log(e);
  //   // e.click();
  //   // setTimeout(() => {
  //   //   e.click();
  //   // }, 500);
  // }

  return (
    <Container>
      <LogoContainer>
        <Link to="/">
          <img src={Logo} alt="brand logo" />
        </Link>
      </LogoContainer>
      <Nav>
        <LinkDiv
          onMouseOver={() => {
            setIsAboutHover(true);
          }}
          onMouseLeave={
            () => {
              setIsAboutHover(false);
            }
            // setTimeout(() => {
            //   setIsHover(false);
            // }, 1000)
          }
        >
          <Link to="/about" className={location === "/about" ? "active" : ""}>
            About Us
          </Link>
          {isAboutHover && (
            <DropdownContainer>
              <DropdownContent
                onMouseOver={() => {
                  setIsWhat(true);
                }}
                onMouseLeave={() => {
                  setIsWhat(false);
                }}
              >
                <div>Mission</div>
                {isWhat && (
                  <DropdownSideContainer>
                    <DropdownSideContent>
                      <Link to="/about">Who we are</Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/about">What we do</Link>
                    </DropdownSideContent>
                  </DropdownSideContainer>
                )}
              </DropdownContent>

              <DropdownContent>
                <Link
                  to="/value"
                  className={location === "/value" ? "active" : ""}
                >
                  Value proposition
                </Link>
              </DropdownContent>
              <DropdownContent>
                <Link
                  to="/leadership"
                  className={location === "/leadership" ? "active" : ""}
                >
                  Leadership
                </Link>
              </DropdownContent>
            </DropdownContainer>
          )}
        </LinkDiv>

        {/* drop down for our services */}
        <LinkDiv
          onMouseOver={() => {
            setIsServicesHover(true);
          }}
          onMouseLeave={() => {
            setIsServicesHover(false);
          }}
        >
          <Link
            to="/value"
            className={location === "/services" ? "active" : ""}
          >
            Our Services
          </Link>
          {isServicesHover && (
            <DropdownContainer>
              <DropdownContent
                onMouseOver={() => {
                  setIsTransHover(true);
                }}
                onMouseLeave={() => {
                  setIsTransHover(false);
                }}
              >
                <div>Translational science</div>
                {isTransHover && (
                  <DropdownSideContainer>
                    <DropdownSideContent>
                      <Link to="/biomarker-solutions">Biomarker solutions</Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/assay-development">Assay development</Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/genomic-science">Genomic science</Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/digital-health">Digital health</Link>
                    </DropdownSideContent>
                  </DropdownSideContainer>
                )}
              </DropdownContent>

              <DropdownContent
                onMouseOver={() => {
                  setIsMarketHover(true);
                }}
                onMouseLeave={() => {
                  setIsMarketHover(false);
                }}
              >
                <div>Market access</div>
                {isMarketHover && (
                  <DropdownSideContainer>
                    <DropdownSideContent>
                      <Link to="/site-and-capacity-development">
                        Site and capacity development
                      </Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/insights-and-surveys">
                        Insights and surveys
                      </Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/pricing-and-reimbursement">
                        Pricing and reimbursement
                      </Link>
                    </DropdownSideContent>
                    <DropdownSideContent>
                      <Link to="/alliance-management">Alliance management</Link>
                    </DropdownSideContent>
                  </DropdownSideContainer>
                )}
              </DropdownContent>
            </DropdownContainer>
          )}
        </LinkDiv>
        <LinkDiv
          onMouseOver={() => {
            setIsInsightsHover(true);
          }}
          onMouseLeave={() => {
            setIsInsightsHover(false);
          }}
        >
          <Link
            to="/insights"
            className={location === "/insights" ? "active" : ""}
          >
            Insights
          </Link>
          {isInsightsHover && (
            <DropdownContainer>
              <DropdownContent>
                <Link to="/insights">Articles</Link>
              </DropdownContent>
            </DropdownContainer>
          )}
        </LinkDiv>
      </Nav>

      {isMenu ? (
        <MenuBackdrop
          onClick={() => {
            setIsMenu(!isMenu);
          }}
        >
          <MenuContainer onClick={(event) => event.stopPropagation()}>
            <Close
              onClick={() => {
                setIsMenu(!isMenu);
              }}
            >
              <img src={close} alt="" />
            </Close>
            <LinkDiv>
              <Link
                to="/about"
                className={location === "/about" ? "active" : ""}
              >
                About Us
              </Link>
            </LinkDiv>
            <LinkDiv>
              <Link
                to="/leadership"
                className={location === "/leadership" ? "active" : ""}
              >
                Leadership
              </Link>
            </LinkDiv>
            <LinkDiv>
              <Link
                to="/value"
                className={location === "/value" ? "active" : ""}
              >
                Value Proposition
              </Link>
            </LinkDiv>
            <LinkDiv>
              <Link
                to="/insights"
                className={location === "/insights" ? "active" : ""}
              >
                Insights
              </Link>
            </LinkDiv>
          </MenuContainer>
        </MenuBackdrop>
      ) : (
        <HambugerMenu
          onClick={() => {
            setIsMenu(!isMenu);
          }}
        >
          <img src={menu} alt="" />
        </HambugerMenu>
      )}
    </Container>
  );
};

export default Header;
