import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 6em;
  margin: 0 ${(props) => props.margin};
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    margin: 0;
    padding-bottom: 0em;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  padding: 3.5rem;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  font-family: Monteserrat;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: var(--neutral-900, #111827);
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    border-radius: 0em;
    display: flex;
    width: 100%;
    padding: 3rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;
export const MainHeading = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: Clash Grotesk Variable;
  margin-bottom: 1em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    display: none;
  }
`;
export const Heading = styled.div`
  color: var(--neutral-100, #f3f4f6);
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1em;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    color: var(--neutral-100, #f3f4f6);
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const Top = styled.div`
  width: 100%;
  justify-content: space-between;
`;
export const Bottom = styled.div`
  width: 100%;
`;
export const Div = styled.div`
  display: flex;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;
export const Card = styled.div`
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid var(--neutral-800, #1f2937);
  background: var(--neutral-900, #111827);
  &:hover {
    border: 2px solid #10689b;
    background: var(--neutral-900, #111827);
    box-shadow: 24px 20px 25px -5px rgba(95, 116, 157, 0.1);
  }
  p {
    color: var(--neutral-400, #9ca3af);
    text-align: ${(props) => props.align};
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 20.56em;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid var(--neutral-800, #1f2937);
    background: var(--neutral-900, #111827);
    p {
      color: var(--neutral-400, #9ca3af);
      text-align: ${(props) => props.align};
      font-feature-settings: "salt" on;
      width: 18.5625rem;
      font-family: Monteserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
`;
export const CardHeading = styled.div`
  color: var(--primary-50, #f5f5ff);
  text-align: ${(props) => props.align};
  font-size: 1.5rem;
  font-family: Monteserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    color: var(--primary-50, #f5f5ff);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const ImageContainer = styled.div`
  width: 3.6875rem;
  height: 8.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.3em auto;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 5.9375rem;
    height: 5.9375rem;
    margin: 0 auto;
  }
`;
