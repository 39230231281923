import FooterLogo from "../../assets/images/sharp-footer-logo.png";
import { Link } from "react-router-dom";
import { Container, ColOne, ColTwo, ColThree } from "./styles";

const Footer = () => {
  return (
    <Container>
      <ColOne>
        <h3>Company</h3>
        <Link to="/about">
          <p>About us</p>
        </Link>
        <p>
          <a href="/#contact">Contact</a>
        </p>
      </ColOne>
      <ColTwo>
        <h3>Social</h3>
        <p>
          <a href="https://www.linkedin.com/company/metaphor-laboratory-partners/">
            LinkedIn
          </a>{" "}
        </p>
      </ColTwo>
      <ColThree>
        <img src={FooterLogo} alt="logo" />
        <p>
          © {`${new Date().getFullYear()}`} Metaphorlab. All rights reserved.
        </p>
      </ColThree>
    </Container>
  );
};
export default Footer;
