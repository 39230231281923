import Header from "../../components/header";

import Footer from "../../components/footer";

const BiomarkerSolution = () => {
  return (
    <>
      <Header />

      <h2>Biomarker Solution</h2>

      <Footer />
    </>
  );
};

export default BiomarkerSolution;
