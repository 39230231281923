import React from "react";
import { Link } from "react-router-dom";
import {
  CardWrapper,
  CardHeader,
  CardBody,
  CardFooter,
  ReadButton,
} from "./styles";

const Card = ({ insight }) => {
  return (
    <CardWrapper>
      <CardHeader>{insight.fulldate || insight.date}</CardHeader>
      <CardBody>{insight.title || insight.title.rendered}</CardBody>
      <CardFooter>
        <Link to={`/insights/${insight.id}`}>
          <ReadButton> Read </ReadButton>
        </Link>
      </CardFooter>
    </CardWrapper>
  );
};

export default Card;
