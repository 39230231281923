import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  width: 100%;
  padding: 6rem 7.5rem;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    padding: 0 5%;
    padding-top: 3.81em;
    padding-bottom: 6em;
  }
`;
export const Heading = styled.div`
  color: var(--neutral-800, #1f2937);
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.5em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    color: var(--neutral-800, #1f2937);
    margin-bottom: 0.5em;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const SubHeading = styled.div`
  color: var(--neutral-500, #6b7280);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    margin-bottom: 0.5em;
    color: var(--neutral-500, #6b7280);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 2em;
  }
`;
export const Div = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    gap: 0em;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
`;
export const Details = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  label {
    color: var(--neutral-500, #6b7280);
    font-feature-settings: "salt" on;

    /* Paragraph/Medium/Regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
  input {
    width: 100%;
    height: 2.75em;
    border-radius: 0.375rem;
    border: none;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    /* Paragraph/Medium/Regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    display: flex;
    padding: 0.625rem 0.875rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
  }
`;
export const Message = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 1.5em;
  /* justify-content: space-between; */
  label {
    color: var(--neutral-500, #6b7280);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
  }
`;
export const MessageText = styled.textarea`
  width: 100%;
  height: 13.812em;
  display: flex;
  padding: 0.625rem 0.875rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;
export const Send = styled.input`
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  width: 15.8125rem;
  height: 3.5rem;
  padding: 1.1875rem 0rem 1.125rem 0rem;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 2rem;
  background: #10689b;
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1em;
  &:hover {
    opacity: 0.8;
    transition: 1s;
  }
`;
