import { motion } from "framer-motion";
import {
  Container,
  Heading,
  SubHeading,
  Div,
  Details,
  Message,
  MessageText,
  Send,
} from "./styles";
export default function ContactUs({ contact }) {
  return (
    <motion.div
      initial={{
        opacity: 0,
        // scale: 0.8,
        translateY: 300,
      }}
      whileInView={{
        opacity: 1,
        // scale: 1,
        translateY: 0,
      }}
      transition={{
        duration: 0.5,
        delay: 0,
      }}
    >
      <Container
        ref={contact}
        id="contact"
        onSubmit={(event) => {
          event.preventDefault();

          let formData = new FormData(event.target);
          let formProps = Object.fromEntries(formData);

          window.location.href = `mailto:Tunde@metaphorlaboratory.com?subject=${encodeURIComponent(
            `Message from ${formProps.lastName} ${formProps.firstName}`
          )}&body=${encodeURIComponent(formProps.message)}`;
        }}
      >
        <Heading>Contact Us</Heading>
        <SubHeading>
          We’d love to hear from you, whether its an enquiry or feedback
        </SubHeading>
        <Div>
          <Details>
            <label htmlFor="First name">First Name</label>
            <input name="firstName" type="text" required />
            <label htmlFor="">Last Name</label>
            <input name="lastName" type="text" />
            <label htmlFor="">Email Address</label>
            <input name="email" type="email" required />
          </Details>
          <Message>
            <label htmlFor="">Message</label>
            <MessageText name="message" required />
          </Message>
        </Div>
        <Send type="submit" value="Send" />
      </Container>
    </motion.div>
  );
}
