import styled from "styled-components";

export const Container = styled.div`
  height: 80.06em;
  width: 100%;
  padding: 0 8.5%;
  position: relative;
  padding-top: 8.5em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    padding: 0 5%;
    /* margin-top: 4.25em; */
    padding-top: 3.81em;
    padding-bottom: 7.02em;
  }
`;
export const HeroImage = styled.div`
  position: absolute;
  right: -10;
  top: -10em;
  z-index: -10;
  img {
    width: 120%;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    top: 0em;
    img {
      width: 120%;
      right: 0;
      height: 500%;
    }
    /* display: none; */
  }
`;
export const HeroText = styled.div`
  z-index: 100;
  width: 37.5625rem;
  margin-bottom: 0.8em;
  color: var(--neutral-800, #1f2937);
  font-size: 3.8em;
  font-style: normal;
  font-family: Clash Grotesk Variable;
  font-weight: 500;
  line-height: normal;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    color: var(--neutral-800, #1f2937);
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const ContactUs = styled.a`
  z-index: 100;
  display: flex;
  width: 15.8125rem;
  height: 3.5rem;
  padding: 1.1875rem 5.1875rem 1.125rem 5.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2rem;
  background: #10689b;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transition: 1s;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    margin: 0 auto;
  }
`;
export const SubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14.5em;
  margin-bottom: 5.5em;
  gap: 1.5em;
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    flex-direction: column;
    align-items: center;
    margin-top: 5.5em;
    margin-bottom: 5.5em;
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  width: 36.5rem;
  padding: 2rem 1.5rem;
  flex-direction: column;
  /* align-items: flex-start; */
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  div {
    width: 100%;
    color: var(--neutral-800, #1f2937);
    font-size: 2rem;
    font-family: Clash Grotesk Variable;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    width: 100%;
    color: var(--neutral-500, #6b7280);
    font-feature-settings: "salt" on;
    /* Paragraph/Medium/Regular */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding-bottom: 2em;
    border-bottom: 1px solid var(--primary-200, #c3c4fe);
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    p {
      padding-bottom: 1.1em;
      border-bottom: 1px solid var(--primary-200, #c3c4fe);
    }
    width: 100%;
  }
`;
export const RightContainer = styled.div`
  display: flex;
  width: 36.4375rem;
  padding: 3.5rem;
  align-items: flex-start;
  border-radius: 1rem;
  border: 1px solid var(--primary-200, #c3c4fe);
  background: rgba(255, 255, 255, 0.88);
  img {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mobile.md}) {
    width: 100%;
    padding: 1.5em;
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.75rem;
  img {
    width: 100%;
  }
`;
